var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"orderissue-list"}},[(_vm.title)?_c('v-card-title',[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap py-0"},[_c('div',{staticClass:"d-flex align-center pb-5"}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pb-5"})],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Display.DateTime(item.Created))+" ")]}},{key:"item.Title",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-overflow-ellipses",staticStyle:{"width":"180px"}},'div',attrs,false),on),[_vm._v(_vm._s(item.Title))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Title))])])]}},{key:"item.Notes",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-overflow-ellipses",staticStyle:{"width":"280px"}},'div',attrs,false),on),[_vm._v(_vm._s(item.Notes))])]}}],null,true)},[_c('span',[_c('pre',{staticClass:"notes"},[_vm._v(_vm._s(item.Notes))])])])]}},{key:"item.Resolved",fn:function(ref){
var item = ref.item;
return [(item.Resolved)?_c('v-icon',{attrs:{"size":"18","color":_vm.booleanIcons(item.Resolved).variant}},[_vm._v(_vm._s(_vm.booleanIcons(item.Resolved).icon))]):_c('action-confirm-dialog',{attrs:{"dialogTitle":("Resolve " + (item.Title)),"commentTitle":"Resolution","commentPlaceholder":"Please enter an explanation of how this was resolved."},on:{"confirmed":function (comment) { return _vm.Resolve(item, comment); }},scopedSlots:_vm._u([{key:"information",fn:function(){return [_c('p',[_vm._v(_vm._s(item.Notes))])]},proxy:true}],null,true)},[_c('v-btn',{attrs:{"x-small":"","text":"","color":"primary"}},[_vm._v("Resolve")])],1)]}},{key:"item.ResolvedById",fn:function(ref){
var item = ref.item;
return [(item.ResolvedById)?_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'user-view', params: { id: item.ResolvedById } }}},[_vm._v(" "+_vm._s(item.ResolvedById_FullName)+" ")]):_vm._e()]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }