/* OrderIssue List, Vue Component */
<template>
  <v-card id="orderissue-list">
    <v-card-title v-if="title">{{title}}</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap py-0">
      <div class="d-flex align-center pb-5">


      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">

        <!-- <v-text-field
          v-model="search.all"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Fields"
          class="mr-4"
        ></v-text-field> -->



        <!-- <OrderSelect class="mr-4" v-model="filter.OrderId" :outlined="true" :inverted="false" :hide-details="true" :dense="true" /> -->



        <!-- <v-select
          v-model="filter.Resolved"
          :items="filterItems.Resolved"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Filter Resolved"
          class="mr-4"
        ></v-select> -->



        <!-- <UserSelect class="mr-4" v-model="filter.AssignedToId" :outlined="true" :inverted="false" :hide-details="true" :dense="true" /> -->



        <!-- <UserSelect class="mr-4" v-model="filter.ResolvedById" :outlined="true" :inverted="false" :hide-details="true" :dense="true" /> -->


      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableColumns"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      class="text-no-wrap"
    >


		<template #[`item.Created`]="{item}">
      {{ Display.DateTime(item.Created) }}
    </template>

    <template #[`item.Title`]="{item}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="text-overflow-ellipses" style="width: 180px;">{{ item.Title }}</div>
        </template>
        <span>{{ item.Title }}</span>
      </v-tooltip>
    </template>

     <template #[`item.Notes`]="{item}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="text-overflow-ellipses" style="width: 280px;">{{ item.Notes }}</div>
        </template>
        <span><pre class="notes">{{ item.Notes }}</pre></span>
      </v-tooltip>
    </template>

    <template #[`item.Resolved`]="{item}">
      <v-icon size="18" v-if="item.Resolved" :color="booleanIcons(item.Resolved).variant">{{ booleanIcons(item.Resolved).icon }}</v-icon>
      <action-confirm-dialog v-else @confirmed="(comment) => Resolve(item, comment)" :dialogTitle="`Resolve ${item.Title}`" commentTitle="Resolution" commentPlaceholder="Please enter an explanation of how this was resolved.">
        <template v-slot:information>
          <p>{{ item.Notes }}</p>
        </template>
        <v-btn x-small text color="primary">Resolve</v-btn>
      </action-confirm-dialog>
    </template>

    <template #[`item.ResolvedById`]="{item}">
      <router-link v-if="item.ResolvedById" class="font-weight-medium text-decoration-none"
        :to="{ name: 'user-view', params: { id: item.ResolvedById } }">
        {{ item.ResolvedById_FullName }}
          </router-link>
    </template>

    </v-data-table>
  </v-card>


</template>

<script>

import store from '@/store'

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
  mdiCheck
} from '@mdi/js'

import { ref, watch, computed, toRefs } from '@vue/composition-api'
import * as Display from '@/datahelpers/displayTypes'
import ActionConfirmDialog from '@/views/helpers/ActionConfirmDialog'
// import OrderSelect from '@/views/order/OrderSelect'
// import UserSelect from '@/views/user/UserSelect'

export default {
  props: {
    OrderId: {
      type: Number,
      required: true
    },
    title: {
      required: false,
      default: "Order Issues"
    },
    AssignedToId: Number
  },
  components: {
    ActionConfirmDialog
  //   OrderSelect,
	// 	UserSelect
  },
  setup(props) {

  const { OrderId, AssignedToId } = toRefs(props);

  // define cols
  const tableColumns = [
    { text: 'Timestamp', value: 'Created' },
    // { text: 'Order Reference', value: 'OrderId_Reference' },
		{ text: 'Title', value: 'Title' },
		{ text: 'Notes', value: 'Notes' },
		// { text: 'Assigned', value: 'AssignedToId_FullName' },
    { text: 'Resolved', value: 'Resolved' },
		{ text: 'By', value: 'ResolvedById' },
    {
      text: '',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  // threee params for fetching
  const search = ref({});
  const filter = ref({
    OrderId: (OrderId ? OrderId.value : null),
    AssignedToId: (AssignedToId ? AssignedToId.value : null)
  });
  const options = ref({
    sortBy: ['Created'],
    sortDesc: [true],
    itemsPerPage: 10
  });
  const footerProps = {
    'items-per-page-options': [10, 20, 50, 100, -1]
  };

  // items
  const items = computed(() => store.state.app.OrderIssues || []);
  const totalItems = computed(() => store.state.app.OrderIssuesTotal || 0);
  const selectedItems = ref([]);
  const loading = ref(false);

  // data for filter fields
  const filterItems = ref({
    Resolved: [{"text":"Resolved","value":true},{"text":"Un-Resolved","value":false}]
  });

  // method for data loading via vuex
  const fetchData = () => {
    store
      .dispatch('app/fetchOrderIssues', {
        search: search.value,
        options: options.value,
        filter: filter.value,
      })
       .then(response => {
         loading.value = false
       })
      .catch(error => {
        console.log(error)
      })
  }

  watch([search, filter, options], () => {

    // start loading
    loading.value = true
    fetchData()
  }, { deep: true });


  const booleanIcons = status => {
    if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
    return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
  }

  const Resolve = (orderIssue, comment) => {

    store.dispatch('app/resolveOrderIssue', {
      id: orderIssue.Id,
      entity: orderIssue,
      comment: comment
    })
    .then(response => {

      //response.data.Id
      fetchData();
    })
    .catch((error) => {
      console.log(error);
    });
  }

  return {
      Resolve,
      tableColumns,
      search,
      filterItems,
      filter,
      options,
      totalItems,
      items,
      loading,
      selectedItems,
      footerProps,
      booleanIcons,
      Display,
      icons: {
        mdiPlus,
        mdiCheck
        //mdiDeleteOutline,
        //mdiEyeOutline,
        //mdiPencilOutline,
      },
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
