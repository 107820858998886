/* Order Summary, Vue Component */
<template>
    <v-card class="entity-view">
      <v-card-title>{{ title }}</v-card-title>

      <v-card-text class="d-flex align-start justify-space-between flex-wrap pb-0">

      <div class="fields d-flex align-start flex-wrap pb-0">

          <div>
            <h4>Reference</h4>
            <output>{{ entity.Reference }}</output>
          </div>

          <div v-if="entity.CheckoutReference && entity.CheckoutReference != entity.Reference">
            <h4>Checkout</h4>
            <output>{{ entity.CheckoutReference }}</output>
          </div>

          <div>
            <h4>Status</h4>
            <output><order-status-chip v-if="entity.StatusId_Name" :status="entity.StatusId_Name"></order-status-chip></output>
          </div>

          <div>
            <h4>Order Date</h4>
            <output>{{ Display.DateTime(entity.Created) }}</output>
          </div>

          <div>
            <h4>Last Updated</h4>
            <output>{{ Display.DateTime(entity.Updated) }}</output>
          </div>

          <div v-if="entity.S10Tracking">
            <h4>S10</h4>
            <output>{{ entity.S10Tracking }}</output>
          </div>

      </div>

      <div class="fields d-flex align-start flex-wrap pb-0">

        <div>
          <h4>Member</h4>
          <output><router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'member-view', params: { id: entity.MemberId } }">
        {{ entity.MemberId_Reference }}
          </router-link></output>
        </div>

        <div>
          <h4>Name</h4>
          <output>{{ entity.MemberId_FullName }}</output>
        </div>

      </div>


      <div class="fields d-flex align-start flex-wrap pb-0">

        <div>
          <h4>Product</h4>
          <output>{{ entity.OrderTypeShort }}</output>
        </div>

        <div v-if="entity.ProductId">
          <h4>Product Dims</h4>
          <output>{{ entity.ProductId_Length }}x{{ entity.ProductId_Width }}x{{ entity.ProductId_Height }} mm</output>
        </div>

        <div v-if="entity.CustomLength">
          <h4 v-if="entity.ProductId">Bespoke Dims</h4>
          <h4 v-else-if="entity.QuotePrice">Quote Dims</h4>
          <h4 v-else>Dimensions</h4>
          <output>{{ entity.CustomLength }}x{{ entity.CustomWidth }}x{{ entity.CustomHeight }} mm</output>
        </div>

        <div>
          <h4>Item Description</h4>
          <output>{{ entity.ItemDescription }}</output>
        </div>

        <div v-if="entity.HeavyItem">
          <h4>Heavy Item</h4>
          <output class="d-flex flex-wrap">
            <v-chip class="mr-1 mb-1" x-small color="error">Item over 30KG</v-chip>
          </output>
        </div>

        <div v-if="entity.ExtrasNames && entity.ExtrasNames.length > 0">
          <h4>Extras</h4>
          <output class="d-flex flex-wrap">
            <v-chip class="mr-1 mb-1" v-for="extra in entity.ExtrasNames" x-small :key="extra" color="success">{{ extra }}</v-chip>
          </output>
        </div>

      </div>

      <div class="fields d-flex align-start flex-wrap pb-0">

        <div>
          <h4>Retailer</h4>
          <output>{{ entity.RetailerName }}</output>
        </div>

        <div>
          <h4>Reference</h4>
          <output>{{ entity.RetailerReference }}</output>
        </div>

        <div>
          <h4>Tracking Reference</h4>
          <output>{{ entity.RetailerTrackingReference }}</output>
        </div>

        <div>
          <h4>Expected Date</h4>
          <output>{{ Display.DisplayDate(entity.RetailerExpectedDeliveryDate) }}</output>
        </div>

        <div v-if="entity.ExpectMultipleItems">
          <v-chip color="warning v-chip-light-bg" outlined x-small>Expect Multiple Items</v-chip>
        </div>

        </div>

        <div class="fields d-flex align-start flex-wrap pb-0">

        <div v-if="entity.OriginalOrderId">
          <h4>Original Order</h4>
          <output><router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'order-view', params: { id: entity.OriginalOrderId } }">
        {{ entity.OriginalOrderId_Reference }}
          </router-link></output>
        </div>

      </div>

        <div v-if="entity.ManifestId" class="fields d-flex align-start flex-wrap pb-0">

          <div>
            <h4>Manifest</h4>
            <output>
              <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'manifest-view', params: { id: entity.ManifestId } }">
        {{ entity.ManifestId_CountryId_Name }} {{ Display.DisplayDate(entity.ManifestId_ManifestDate) }}
          </router-link></output>
          </div>
        </div>

        <div class="my-6 ml-auto d-flex justify-right">

          <slot></slot>
        </div>

      </v-card-text>

    </v-card>

</template>

<script>
  import store from '@/store'
  import { useRouter } from '@core/utils'
  import * as Display from '@/datahelpers/displayTypes'
  import OrderStatusChip from '@/views/orderstatus/OrderStatusChip'

  import {
    mdiPencilOutline,
    mdiCheckboxBlankOutline,
    mdiCheckboxMarkedOutline,
    mdiClose
  } from '@mdi/js'

  import { toRefs  } from '@vue/composition-api'

  export default {
    props: {
      title: {
         type: String,
         required: true
      },
      order: {
        type: Object,
        required: true
      }
    },
    components: {
        OrderStatusChip
    },
    setup(props) {

      const { title, order } = toRefs(props);

      const booleanIcons = status => {
        if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
        return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
      }

     return {
        entity: order,
        Display,
        booleanIcons,
        icons: {
          mdiPencilOutline,
          mdiClose
        },
      };
    }
  }
</script>

<style lang="scss" scoped>

</style>

