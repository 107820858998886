/* Order Summary, Vue Component */
<template>
    <v-card-text class="d-flex align-center justify-space-between flex-wrap py-6 px-0">

      <v-row>
        <v-col cols="12" md="6">
      <v-simple-table>
        <thead>
          <tr>
            <th colspan="2">Ordered</th>
          </tr>
        </thead>
        <tbody>

      <tr v-if="entity.ProductId">
        <td>Product Name</td>
        <td>{{ entity.ProductId_Name }}</td>
      </tr>

		  <tr v-if="hasCustomDimensions">
        <td>Custom Dimensions</td>
        <td>
          <div v-if="hasCustomDimensions">{{ entity.CustomLength }}x{{ entity.CustomWidth }}x{{ entity.CustomHeight }} mm</div>
          <div v-else>N/A</div>
        </td>
      </tr>

      <tr>
        <td>Billing Address</td>
        <td>
          <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'address-view', params: { id: entity.BillingAddressId } }">
        {{ entity.BillingAddressId_Summary }}<br />{{ entity.BillingAddressId_SummaryExtended }}
          </router-link>
        </td>
      </tr>

      <tr v-if="entity.RetailerDeliveryCharge && entity.RetailerDeliveryCharge > 0">
        <td>Retailer Delivery Charge</td>
        <td>{{ Display.Money(entity.RetailerDeliveryCharge) }}</td>
      </tr>

      <tr v-if="entity.DiscountCode">
        <td>Discount Code</td>
        <td>{{ entity.DiscountCode }}</td>
      </tr>

      <tr v-if="entity.QuoteNarrative">
        <td>Quote Narrative</td>
        <td>{{ (entity.QuoteNarrative) }}</td>
      </tr>

        </tbody>
      </v-simple-table>

        </v-col>

        <v-col cols="12" md="6">
      <v-simple-table>
        <thead>
          <tr>
            <th colspan="2">Price</th>
          </tr>
        </thead>
        <tbody>

      <tr v-if="entity.ProductId">
        <td><strong>Product Price</strong></td>
        <td><strong>{{ Display.Money(entity.ProductId_Price) }}</strong></td>
      </tr>

      <tr v-if="entity.BespokePrice">
        <td><strong>Bespoke Price</strong></td>
        <td><strong>{{ Display.Money(entity.BespokePrice) }}</strong></td>
      </tr>

      <template v-if="entity.QuoteBreakdownItems && entity.QuoteBreakdownItems.length > 0">
      <tr v-for="(item, itemIndex) in entity.QuoteBreakdownItems" :key="itemIndex">
        <td>{{ item.Key }}</td>
        <td>{{ Display.Money(item.Value) }}</td>
      </tr>
      </template>

      <tr v-if="entity.QuotePrice">
        <td><strong>Quoted total</strong></td>
        <td><strong>{{ Display.Money(entity.QuotePrice) }}</strong></td>
      </tr>

        </tbody>
      </v-simple-table>

        </v-col>
      </v-row>

    </v-card-text>
</template>

<script>
  import store from '@/store'
  import { useRouter } from '@core/utils'
  import * as Display from '@/datahelpers/displayTypes'
  import OrderStatusChip from '@/views/orderstatus/OrderStatusChip'

  import {
    mdiPencilOutline,
    mdiCheckboxBlankOutline,
    mdiCheckboxMarkedOutline,
    mdiClose
  } from '@mdi/js'

  import { toRefs, computed  } from '@vue/composition-api'

  export default {
    props: {
      order: {
        type: Object,
        required: true
      }
    },
    components: {
        OrderStatusChip
    },
    setup(props) {

      const { order } = toRefs(props);

      const booleanIcons = status => {
        if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
        return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
      }

    const hasCustomDimensions = computed(() => (order.value && (order.value.CustomLength || order.value.CustomWidth || order.value.CustomHeight)));

      return {
        hasCustomDimensions,
        entity: order,
        Display,
        booleanIcons,
        icons: {
          mdiPencilOutline,
          mdiClose
        },
      };
    }
  }
</script>

<style lang="scss" scoped>

</style>

