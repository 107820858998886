/* OrderLog List, Vue Component */
<template>
  <v-card id="orderlog-list">
    <v-card-title v-if="title">{{title}}</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap py-0">
      <div class="d-flex align-center pb-5">


      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">

        <!-- <v-text-field
          v-model="search.all"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Fields"
          class="mr-4"
        ></v-text-field> -->



        <!-- <v-select
          v-model="filter.Action"
          :items="filterItems.Action"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Filter Action"
          class="mr-4"
        ></v-select> -->

        <!-- <UserSelect class="mr-4" v-model="filter.UserId" :outlined="true" :inverted="false" :hide-details="true" :dense="true" /> -->


      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableColumns"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      class="text-no-wrap"
    >

      <template #[`item.Created`]="{item}">
      {{ Display.DateTime(item.Created) }}
    </template>

    <template #[`item.UserId`]="{item}">
      <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'user-view', params: { id: item.UserId } }">
        {{ item.UserId_FullName }}
          </router-link>
    </template>

    </v-data-table>
  </v-card>
</template>

<script>

import store from '@/store'

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiPencilOutline,
} from '@mdi/js'

import { ref, watch, computed, toRefs } from '@vue/composition-api'
import * as Validators from '@/datahelpers/validation'
import * as Display from '@/datahelpers/displayTypes'
import OrderSelect from '@/views/order/OrderSelect'
import UserSelect from '@/views/user/UserSelect'

export default {
  props: {
    OrderId: {
      type: Number,
      required: true
    },
    title: {
      required: false,
      default: "Order History"
    }
  },
  components: {
    OrderSelect,
		UserSelect
  },
  setup(props) {

  const { OrderId } = toRefs(props);

  // define cols
  const tableColumns = [
    // { text: 'Order Reference', value: 'OrderId_Reference' },
		{ text: 'Timestamp', value: 'Created' },
    { text: 'User', value: 'UserId' },
    { text: 'Action', value: 'Action' },
		{ text: 'Notes', value: 'Notes' },
  ];

  // threee params for fetching
  const search = ref({});
  const filter = ref({
    OrderId: OrderId.value
  });
  const options = ref({
    sortBy: ['Created'],
    sortDesc: [true],
    itemsPerPage: 10
  });
  const footerProps = {
    'items-per-page-options': [10, 20, 50, 100, -1]
  };

  // items
  const items = computed(() => store.state.app.OrderLogs || []);
  const totalItems = computed(() => store.state.app.OrderLogsTotal || 0);
  const selectedItems = ref([]);
  const loading = ref(false);

  // data for filter fields
const filterItems = ref({
    Action: [
      {"text":"Action 1","value":"Action 1"},
      {"text":"Action 2","value":"Action 2"},
      {"text":"Action 3","value":"Action 3"}
    ]
  });


  // method for data loading via vuex
  const fetchData = () => {
    store
      .dispatch('app/fetchOrderLogs', {
        search: search.value,
        options: options.value,
        filter: filter.value,
      })
       .then(response => {
         loading.value = false
       })
      .catch(error => {
        console.log(error)
      })
  }

  watch([search, filter, options], () => {

    // start loading
    loading.value = true
    fetchData()
  }, { deep: true });



    const actionOptions = [
      { title: 'View', icon: mdiEyeOutline, target: 'orderlog-view' },
      { title: 'Edit', icon: mdiPencilOutline, target: 'orderlog-edit' }
    ]

    return {
      tableColumns,
      search,
      filterItems,
      filter,
      options,
      totalItems,
      items,
      loading,
      selectedItems,
      actionOptions,
      footerProps,

      Validators,
      Display,
      icons: {
        mdiPlus,
        //mdiDeleteOutline,
        //mdiEyeOutline,
        //mdiPencilOutline,
      },
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
