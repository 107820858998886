/* Order Summary, Vue Component */
<template>
    <v-card-text class="d-flex align-center justify-space-between flex-wrap py-6 px-0">

      <v-row>
        <v-col cols="12" md="6">
      <v-simple-table>
        <thead>
          <tr>
            <th colspan="2">Additional Details</th>
          </tr>
        </thead>
        <tbody>

      <tr>
        <td>Member Email</td>
        <td class="d-flex align-center">{{ entity.MemberId_Email }}
        &nbsp;<v-icon :title="entity.EmailValidated ? 'Validated Email' : 'Not Validated'" size="16" :color="yesNoIcons(entity.EmailValidated).variant">{{ yesNoIcons(entity.EmailValidated).icon }}</v-icon>
        </td>
      </tr>

      <tr>
        <td>Delivery Address</td>
        <td><router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'address-view', params: { id: entity.DeliveryAddressId } }">
        {{ entity.DeliveryAddressId_Summary }}<br />{{ entity.DeliveryAddressId_SummaryExtended }}
          </router-link>
        </td>
      </tr>

		  <tr>
        <td>Billing Address</td>
        <td>
          <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'address-view', params: { id: entity.BillingAddressId } }">
        {{ entity.BillingAddressId_Summary }}<br />{{ entity.BillingAddressId_SummaryExtended }}
          </router-link>
        </td>
      </tr>

        </tbody>
      </v-simple-table>

        </v-col>

        <v-col cols="12" md="6">
      <v-simple-table>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>

      <tr>
        <td>Retailer Order Date</td>
        <td>{{ Display.DisplayDate(entity.RetailerOrderDate) }}</td>
      </tr>

      <tr>
        <td>Import. Customs Declr.</td>
        <td><v-icon size="18" :color="booleanIcons(entity.ImportationCustomsDeclaration).variant">{{ booleanIcons(entity.ImportationCustomsDeclaration).icon }}</v-icon></td>
      </tr>

      <tr>
        <td>Original Retailer Docs.</td>
        <td><v-icon size="18" :color="booleanIcons(entity.OriginalRetailerDocumentation).variant">{{ booleanIcons(entity.OriginalRetailerDocumentation).icon }}</v-icon></td>
      </tr>

      <tr>
        <td>Terms &amp; Conditions</td>
        <td><v-icon size="18" :color="booleanIcons(entity.TermsAndConditions).variant">{{ booleanIcons(entity.TermsAndConditions).icon }}</v-icon></td>
      </tr>


        </tbody>
      </v-simple-table>

        </v-col>
      </v-row>

    </v-card-text>
</template>

<script>
  import store from '@/store'
  import { useRouter } from '@core/utils'
  import * as Display from '@/datahelpers/displayTypes'
  import OrderStatusChip from '@/views/orderstatus/OrderStatusChip'

  import {
    mdiPencilOutline,
    mdiCheckboxBlankOutline,
    mdiCheckboxMarkedOutline,
    mdiClose,
    mdiBookmarkCheck,
    mdiAccountQuestion
  } from '@mdi/js'

  import { toRefs, computed  } from '@vue/composition-api'

  export default {
    props: {
      order: {
        type: Object,
        required: true
      }
    },
    components: {
        OrderStatusChip
    },
    setup(props) {

      const { order } = toRefs(props);

      const booleanIcons = status => {
        if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
        return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
      }

      const yesNoIcons = status => {
        if (status) return { variant: 'success', icon: mdiBookmarkCheck }
        return { variant: 'warning', icon: mdiAccountQuestion }
      }

    const hasCustomDimensions = computed(() => (order.value && (order.value.CustomLength || order.value.CustomWidth || order.value.CustomHeight)));

      return {
        hasCustomDimensions,
        entity: order,
        Display,
        booleanIcons,
        yesNoIcons,
        icons: {
          mdiPencilOutline,
          mdiClose
        },
      };
    }
  }
</script>

<style lang="scss" scoped>

</style>

